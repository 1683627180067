import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import routes from '../Admin/routes';
import logo from './Images/logoadefmod2.png'
import { ServiceUtilisateur } from '../ServiceUtilisateur';




export default function Sidebar() {
    
    //const { collapseSidebar } = useProSidebar();
    const LesRoutes = routes.map(route => (
        <ListItem key={route.key}>
            <ListItemButton href={route.route}>
                <ListItemIcon>
                    {route.icon}
                </ListItemIcon>
                <ListItemText primary={route.name} />
            </ListItemButton>
        </ListItem>


    ))
    const deconnexion = (e) => {
        ServiceUtilisateur.logout()
        window.location.reload()
    }
    return (
        <Box
            sx={{
                textAlign: 'center',
                width: 250,
                height: 50 + 'rem',
                position: 'fixed',
                backgroundColor: "#3f3f3f",
                display: 'flex',
                flexDirection: 'column',
                top: '0px',
                zIndex: 1,
                /*'&:hover': {
                backgroundColor: 'primary.main',
                opacity: [0.9, 0.8, 0.7],
                },*/
            }}
        >
            <Typography sx={{ p: 1, color: 'white',fontSize:"30px" }} variant="overline" display="block" gutterBottom>
                <img width={200} height={200} src={logo} />
            </Typography>
            <Divider variant="middle" sx={{
                backgroundColor: 'white'
            }} />

            <Box sx={{ width: '100%', maxWidth: 360, color: 'white' }}>
                <nav>
                    <List>
                        {LesRoutes}
                        <Divider variant="middle" sx={{
                            backgroundColor: 'white'
                        }} />
                        <ListItem>
                            <ListItemButton onClick={deconnexion}>
                                <ListItemText primary="Déconnexion" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
            </Box>

        </Box>
    )
}