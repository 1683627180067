import React, { useEffect, useState } from 'react';
import vm from '../Images/vm.jpg'
import './accueil.css';
import axios from 'axios';

function VisionEtMission(props) {
    const [dataVision, setDataVision] = useState([]);
    const [dataMission, setDataMission] = useState([]);

    useEffect(() => {
        const Vision = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/vision')
                if (response) {
                    setDataVision(response.data.results[0].vision)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log("Erreur dans le use effect de vision " + error);
            };


        }
        const Mission = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/mission')
                if (response) {
                    setDataMission(response.data.results[0].mission)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log("Erreur dans le use effect de mission " + error);
            };


        }
        Mission();
        Vision();
    }, [])
    // const divstyle = {
    //     backgroundImage:"url"
    // }
    return (
        <div className='uk-section uk-section-default'>
            <div className="row" uk-scrollspy="cls: uk-animation-slide-bottom; target: .uk-card; delay: 300; repeat: false">
                <div className="row col uk-card" style={{ padding: '20px', textAlign: 'justify', paddingLeft: 70 }}>
                    <span className='notre'>Notre</span> <br /> <span className='vismis'>Vision</span>
                    <br /><br />
                    <span>
                        {dataVision}
                    </span>
                </div>
                
                <div style={{marginRight:'20px'}}>
                    <hr />
                </div>
                <div className="row col uk-card" style={{ padding: '20px', paddingLeft: 70, textAlign:'justify' }}>
                    <span className='notre'>Notre</span> <br /> <span className='vismis'>Mission</span>
                    <br /><br />
                    <span>
                        {dataMission}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default VisionEtMission;