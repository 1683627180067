import React, { useEffect, useState } from 'react';
import photo1 from '../Images/produits/adef_p1.jpg'
import './accueil.css';
import axios from 'axios';

function Produit(props) {

    const [dataProds, setDataProds] = useState([]);

    useEffect(() => {
        const Prods = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/prodacc')
                if (response) {
                    setDataProds(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Prods();
    }, [])
    return (
        <div className='uk-section uk-preserve-color' style={{ textAlign: 'start'}}>
            <div style={{ marginTop: 70 }} uk-scrollspy="cls: uk-animation-slide-bottom; target: > div; delay: 1000; repeat: false">
                <div uk-scrollspy-className="uk-animation-slide-bottom">
                    <span style={{ fontSize: "30px" }}><span className='qui'>Quelques</span><br/> <span className='sommesnous'>produits</span></span>
                </div>
                <div uk-slider="autoplay: true; autoplay-interval: 2000" style={{ marginTop: 30 }}>

                    <ul className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-light">
                        {
                            dataProds.map((donne, donnekey)=>{
                                return(
                                    <li className="uk-transition-toggle" tabIndex="1" key={donnekey}>
                                        <img className='imgprodacc' width="300" height="300" src={`http://www.adefapp.adefgroup.com/${donne.photo}`} alt="Image" /><br />
                                        <div className="uk-position-center uk-panel"><h1 className="uk-transition-slide-bottom-small"><button type="button" className="btn btn-outline-dark"><a href='/produitsetservices'>Aller voir</a></button></h1></div>
                                    </li>
                                )
                            })
                        }
                    </ul>

                    <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

                </div>
            </div>
            
        </div>
    );
}

export default Produit;