import React from 'react';
import tel from '../Images/telephone.png'
import mail from '../Images/mail.png'
import home from '../Images/home.png'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';

function InfoContact(props) {
    return (
        <div>
            <div className="uk-section">
                <div className="uk-container" style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <div className='col'>
                        <span style={{ fontSize: '20px'}}><b>Bénin</b> </span>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <LocalPhoneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="00229 98 79 70 70 / 95 06 19 08" />
                            </ListItem>
                            <br />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <EmailIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="infobenin@adefgroup.com" />
                            </ListItem>
                            <br />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <HomeIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Lot 15 Parcelle 050R Cococodji Agbogboville 01 BP 5596 Cotonou" />
                            </ListItem>
                        </List>
                    </div>
                    <div className='col'>
                        <span style={{ fontSize: '20px'}}><b>Togo</b> </span>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <LocalPhoneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="00228 97 61 61 61 / 98 60 46 46" />
                            </ListItem>
                            <br />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <EmailIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="infotogo@adefgroup.com" />
                            </ListItem>
                            <br />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <HomeIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Kagomé Derrière Hôtel Espera 01 BP 3167 Lomé-Togo" />
                            </ListItem>
                        </List>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default InfoContact;