import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ServiceUtilisateur } from '../ServiceUtilisateur';

function ProduitsSelect(props) {
    const [dataProds, setDataProds] = useState([]);
    const [nomCat, setNomCat] = useState([]);
    const [quantite, setQuantite] = useState(0);
    const nav = useNavigate();

    let {codeprod} = useParams()
    
    const LePanier =(produit)=>{
        if(quantite > 0){
            alert(quantite)
            console.log(produit)
            ServiceUtilisateur.addProd(produit)
            nav("/panier");
            window.location.reload()
        }else{
            alert('Veuillez saisir une quantité supérieur à 0')
        }
        
    }
    useEffect(()=>{
        console.log(codeprod)
        const Prods = async () => {
            try {
                const response = await axios.post('http://www.adefapp.adefgroup.com/unproduit',{codeprod})
                if (response) {
                    setDataProds(response.data.results)
                    setNomCat(response.data.result)
                    console.log(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Prods();
    },[])
    return (
        <div className='uk-section uk-preserve-color'>
            <div className="container text-center">
            {
                    dataProds.length > 0 ?
                        <div className="row">
                            <div className="col">
                                <img width="500" height="500" src={`http://www.adefapp.adefgroup.com/${dataProds[0].photo}`} alt="Image" />
                            </div>
                            <div className="col" style={{textAlign:'left'}}>
                                <div className='row' style={{paddingBottom:'15px', fontSize:'20px'}}>
                                    {dataProds[0].description}
                                </div>
                                <div className='row' style={{ paddingBottom: '15px', fontSize: '36px', fontWeight:'bold' }}>
                                    Description
                                </div>
                                <div className='row' style={{ paddingBottom: '15px'}}>
                                    {dataProds[0].details}
                                </div>
                                {
                                    nomCat.length > 0 ?
                                        <div className='row' style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                                            Catégories : {nomCat[0].description}
                                        </div> : <span style={{color:'red'}}>Catégories en cours de recherche...</span>
                                }
                                <div className='row' style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                                    <input onChange={(e) => setQuantite(e.target.value)} value={quantite} className="uk-input uk-form-width-small" type="text" placeholder="Quantité" aria-label="Small" />
                                </div>
                                <div className='row' style={{ fontWeight: 'bold' }}>
                                    <button onClick={() => LePanier({tab :dataProds[0],qte:quantite})} className="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom">Ajouter au panier</button>
                                </div>
                                
                            </div>
                        </div>
                        : 'No data found'
            }
                
            </div> 
        </div>
    );
}

export default ProduitsSelect;