import React from 'react';
import BackImage from '../Images/PROD.jpg'

function Header(props) {
    return (
        <div>
            <div className="uk-height-medium uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style={{backgroundImage: `url(${BackImage})`}}>

                <h1 style={{fontSize:'25px', fontWeight:'bold'}} className="uk-width-1-2@m uk-text-center uk-margin-auto uk-margin-auto-vertical">NOS PRODUITS</h1>

            </div>
        </div>
    );
}

export default Header;