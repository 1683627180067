import React from 'react';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import logo from './Images/logoadefmod.png'
import './Navbar.css'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Avatar from '@mui/material/Avatar';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import { ServiceUtilisateur } from '../ServiceUtilisateur';
function Navbar(props) {
    let n = ServiceUtilisateur.getNbreShop()
    return (
        <>
            <center className='nav1'>
                <div style={{ width:'80%',display: 'flex',flexDirection:'row' }}>
                    <div style={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <Link className='navIcone' style={{ textDecoration: 'none' }} to="https://x.com/AdefInfobenin?s=09"><TwitterIcon /></Link> 
                        <Link className='navIcone' style={{ textDecoration: 'none' }} to="https://www.facebook.com/profile.php?id=61550684836054&mibextid=ZbWKwL"><FacebookIcon /></Link>
                        <Link className='navIcone' style={{ textDecoration: 'none' }} to="https://instagram.com/adefbenin?igshid=bGZnbzVoZTVvaW5k"><InstagramIcon /></Link> 
                    </div>
                    <div style={{ width: '80%',display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <Avatar><LocalPhoneIcon /></Avatar>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                            Bénin : 00229 98 79 70 70 / 95 06 19 08 | Togo : 00228 97 61 61 61 / 98 60 46 46
                        </span>
                    </div>
                    
                </div>
                
            </center>
            <center style={{paddingBottom: '2%' }}>
                <div className="App">
                    <nav style={{ backgroundColor: "white", borderBottom: '1px darkgrey solid', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <a style={{ color: "white" }} href="#">
                                    <a style={{ color: "white", fontSize: "30px" }} href="/accueil">
                                        <img width={100} height={100} src={logo} />
                                    </a>
                                </a>
                                {/* <span style={{ fontSize: "14px", fontWeight:'bold' }}>ADEF</span> */}
                                <a className='menu' href='/accueil'><button type="button" className="btn"><span className='menuspan' style={{ fontSize: "14px" }}>Accueil</span></button></a>
                                <a className='menu' href='/produitsetservices'><button type="button" className="btn"><span className='menuspan' style={{ fontSize: "14px" }}>Nos produits et services</span></button></a>
                                <a className='menu' href='/partenaires'><button type="button" className="btn"><span className='menuspan' style={{ fontSize: "14px" }}>Nos partenaires</span></button></a>
                                <a className='menu' href='/contact'><button type="button" className="btn"><span className='menuspan' style={{ fontSize: "14px" }}>Contactez-Nous</span></button></a>
                            </div>
                            <a href='/panier'><button type="button" className="btn"><span className='menuspan' style={{ fontSize: "14px", padding: '15px' }}>
                                <Badge color="secondary" badgeContent={n} showZero>
                                    <ShoppingCartIcon />
                                </Badge>
                            </span></button></a>
                        </div>

                    </nav>
                </div>
            </center>
        </>
    );
}

export default Navbar;