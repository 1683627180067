import React from 'react';
import Header from './Composants/Header';
import Presentation from './Composants/Presentation';
import LesProduits from './Composants/LesProduits';

function ProduitsServices(props) {
    return (
        <>
            <Header />
            <Presentation />
            <LesProduits />
        </>
    );
}

export default ProduitsServices;