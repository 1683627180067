import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
//import LogoutIcon from '@mui/icons-material/Logout';
//import { ServiceUtilisateur } from '../../ServiceUtilisateur';
import axios from 'axios';

function Dashboard(props) {
    let i=0
    const [NbrCategs, setNbrCategs] = useState([]);
    const [NbrProds, setNbrProds] = useState([]);
    const [NbrPubs, setNbrPubs] = useState([]);
    const [NbrParts, setNbrParts] = useState([]);

    useEffect(() => {
        const Categs = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/categories')
                if (response) {
                    setNbrCategs(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Categs();
        const Prods = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/produits')
                if (response) {
                    setNbrProds(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Prods();
        const Pubs = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/pubs')
                if (response) {
                    setNbrPubs(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Pubs();
        const Parts = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/parts')
                if (response) {
                    setNbrParts(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Parts();
    }, [])
    return (
        <div>
            <Grid marginTop={5} sx={{ display: "flex", flexDirection: "row", border: '0.5px solid #d3d3d3', borderLeft: 0, padding: 1, borderTop: 0, borderRight: 0 }}>
                <Grid md={20}>
                    <Typography variant="h6" component="div">
                        Tableau de board
                    </Typography>
                </Grid>
                <Grid md={2}>
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                        //onClick={deconnexion}
                    >
                        {/* <LogoutIcon /> */}
                    </IconButton>
                </Grid>
            </Grid>

            <Card sx={{ boxShadow: 5, ml: 5, mt: 3, width: "50%" }}>
                <Grid >
                    <Grid sx={{ display: 'flex' }}>

                        <Grid sx={{ p: 2 }}>
                            <div>
                                <span style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'sans-serif' }}>Good Afternoon, {/*ServiceUtilisateur.getNom()} {ServiceUtilisateur.getPrenom()*/}</span>
                                <br />
                                <span style={{ fontSize: '15px', fontWeight: 'semi-bold' }}>Ravi de vous revoir !</span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            <section style={{ width: '80%', marginLeft: '5%', marginTop: '5%' }}>
                <Typography variant="h5" component="div">
                    Suivi en temps réel
                </Typography>
                <br />
                <Grid container spacing={3} sx={{ display: 'flex', backgroundColor: "white", boxShadow: 15 }}>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 90, p: 3, boxShadow: 0 }}>
                            <Typography variant="h6" component="div">
                                Nombre de catégories
                            </Typography>
                            <br />
                            <Typography variant="h4" component="div">
                                {
                                    NbrCategs.length
                                }
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 90, p: 3, boxShadow: 0 }}>
                            <Typography variant="h6" component="div">
                                Nombre de produits
                            </Typography>
                            <br />
                            <Typography variant="h4" component="div">
                                {
                                    NbrProds.length
                                }
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 90, p: 3, boxShadow: 0 }}>
                            <Typography variant="h6" component="div">
                                Nombre de publicités
                            </Typography>
                            <br />
                            <Typography variant="h4" component="div">
                                {
                                    NbrPubs.length
                                }
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 90, p: 3, boxShadow: 0 }}>
                            <Typography variant="h6" component="div">
                                Nombre de partenaires
                            </Typography>
                            <br />
                            <Typography variant="h4" component="div">
                                {
                                    NbrParts.length
                                }
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </section>
        </div>
    );
}

export default Dashboard;