import React from 'react';

function Presentation(props) {
    return (
        <div>
            <div className="uk-section uk-section-default">
                <div className="uk-container">

                    {/* <h3>Section Default</h3> */}

                    <div className="uk-grid-match uk-child-width-1@m" uk-grid="true">
                        <div>
                            <p style={{textAlign:'justify'}}>
                                ADEF couvre une large gamme de produits, fournissant des séries de distributeurs de carburant traditionnels, des séries de distributeurs de carburant à carte IC, des séries de distributeurs de carburant à
                                récupération de vapeur. Tous les produits peuvent être personnalisés en fonction des besoins des différents clients. Nous sommes également spécialisés dans le développement de nouvelles technologies
                                énergétiques : distributeurs de GPL, dispositif de carburant portable GPL, équipement intégré de station de remplissage de GNC, distributeurs de GNL, skid de pompe cryogénique GNL, équipement intégré de station de remplissage de GNL. Nous répondons activement à l'appel national pour la conservation de l'énergie et la réduction des émissions et nous nous consacrons à l'activité de recharge. Nous fournissons un chargeur EV rapide DC/AC pour le marché. Nous fournissons également une interface de charge spéciale faisant référence au State Grid Standard ou à celui exporté vers l'Union européenne ou le Japon. Le chargeur a une interaction homme-machine conviviale et a des fonctions correspondantes telles que le contrôle, le balayage de carte, la communication et la protection. Le système est simple et facile à utiliser.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Presentation;