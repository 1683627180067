import React from 'react';
import Header from './Composants/Header'
import LesPartenaires from './Composants/LesPartenaires';

function Partenaires(props) {
    return (
        <>
            <Header />
            <LesPartenaires />
        </>
    );
}

export default Partenaires;