
// Material Dashboard 2 React layouts
import Dashboard from './Dashboard/Dashboard';
import Produits from './Produits/Produits';
import Publicites from './Publicites/Publicites';
import Categories from './Categories/Categories';
import Partenaires from './Partenaires/Partenaires';
import Apropos from './Apropos/Apropos';
import Annonces from './Annonces/Annonces'
import Commandes from './Commandes/Commandes';
//import Login from "./Component/Login/Login";

// @mui icons
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import GroupsIcon from '@mui/icons-material/Groups';
// import MessageIcon from '@mui/icons-material/Message';
// import LoginIcon from '@mui/icons-material/Login';
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import Login from './Login/Login';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CampaignIcon from '@mui/icons-material/Campaign';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const routes = [
  {
    name: "Tableau de board",
    key: "dashboard",
    icon: <DashboardIcon sx={{color:'white'}} fontSize="small">dashboard</DashboardIcon>,
    route: "/admin/dashboard",
    component: <Dashboard />,
  },
  {
    name: "Catégories",
    key: "categorie",
    icon: <CategoryIcon sx={{color:'white'}} fontSize="small"></CategoryIcon>,
    route: "/admin/categories",
    component: <Categories />,
  },
  {
    name: "Produits",
    key: "produits",
    icon: <ProductionQuantityLimitsIcon sx={{color:'white'}} fontSize="small"></ProductionQuantityLimitsIcon>,
    route: "/admin/produits",
    component: <Produits />,
  },
  {
    name: "Publicité",
    key: "pub",
    icon: <CampaignIcon sx={{color:'white'}} fontSize="small"></CampaignIcon>,
    route: "/admin/publicites",
    component: <Publicites />,
  },
  {
    name: "Partenaires",
    key: "partenaires",
    icon: <HandshakeIcon sx={{color:'white'}} fontSize="small"></HandshakeIcon>,
    route: "/admin/partenaires",
    component: <Partenaires />,
  },
  {
    name: "Contacts",
    key: "contact",
    icon: <ConnectWithoutContactIcon sx={{color:'white'}} fontSize="small"></ConnectWithoutContactIcon>,
    route: "/admin/contacts",
    component: <Partenaires />,
  },
  {
    name: "A propos",
    key: "apropos",
    icon: <ConnectWithoutContactIcon sx={{ color: 'white' }} fontSize="small"></ConnectWithoutContactIcon>,
    route: "/admin/a-propos",
    component: <Apropos />,
  },
  {
    name: "Annonces",
    key: "annonces",
    icon: <CampaignIcon sx={{ color: 'white' }} fontSize="small"></CampaignIcon>,
    route: "/admin/annonces",
    component: <Annonces />,
  },
  {
    name: "Commandes",
    key: "commandes",
    icon: <CampaignIcon sx={{ color: 'white' }} fontSize="small"></CampaignIcon>,
    route: "/admin/commandes",
    component: <Commandes />,
  },

];

export default routes;
