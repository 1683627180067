import logo from './logo.svg';
import './App.css';
import Navbar from './DefaultLayout/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from './DefaultLayout/Footer';

function App() {
  return (
    <>
      <center>
        <Navbar />
        <div style={{ width: '80%' }}>
          <Outlet />
        </div>
        <Footer />
      </center>
    </>
  );
}

export default App;
