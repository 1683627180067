import Sidebar from './DefaultLayout/Sidebar';
import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Navbar from './Admin/DefaultLayout/Navbar';
import './Admin.css'

function Admin() {
    return (
        <>
            <Grid container >
                <Grid md={2} className="sidebar">
                    <Sidebar />
                </Grid>
                <Grid md={10}>
                    {/*getRoutes(routes)*/}
                    <Outlet />
                </Grid>
            </Grid>
        </>
    );
}

export default Admin;
