import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import QuiSommeNous from './Components/QuiSommeNous';
import Vision from './Components/Vision';
import Mission from './Components/Mission';

function Apropos(props) {
    return (
        <Grid spacing={1} marginTop={5}>
            <Grid sx={{ display: "flex", flexDirection: "row", border: '0.5px solid #d3d3d3', borderLeft: 0, padding: 1, borderTop: 0, borderRight: 0 }}>
                <Grid md={10}>
                    <Typography variant="h6" component="div">
                        A propos
                    </Typography>
                </Grid>
            </Grid>
            <QuiSommeNous />
            <Vision />
            <Mission />
        </Grid>
    );
}

export default Apropos;