import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';

function Publicites(props) {
    const [open, setOpen] = useState(false);
    const [titre, setTitre] = useState('')
    const [stitre, setStitre] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [data, setData] = useState([]);
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        console.log(selectedFile)
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const supprimer = (id) => {
        if (window.confirm("Vous êtes sûr de vouloir supprimer ceette publicités ?") == true) {
            axios.delete(`http://www.adefapp.adefgroup.com/pub/${id}`)
                .then((response) => {
                    alert("Suppression éffectuer avec succès")
                    window.location.reload();
                })
                .catch((err) => { alert("Une erreur s'est produite lors de la suppression") })
        }
    };
    const Form = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('titre', titre)
        formData.append('stitre', stitre)
        formData.append('image', selectedFile);
        console.log(formData)
        axios.post('http://www.adefapp.adefgroup.com/pub', formData )
            .then(data => {
                alert('Publicité enregistrer')
                window.location.reload()
            })
            .catch(err => {
                alert('Vérifiez vos informations')
            })
    }
    useEffect(()=>{
        const Pubs = async () =>{
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/pubs')
                if (response) {
                    setData(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };
            
           
        }
        Pubs();
    },[])
    return (
        <Grid spacing={1} marginTop={5}>
            <Grid sx={{ display: "flex", flexDirection: "row", border: '0.5px solid #d3d3d3', borderLeft: 0, padding: 1, borderTop: 0, borderRight: 0 }}>
                <Grid md={10}>
                    <Typography variant="h6" component="div">
                        Les Publicités
                    </Typography>
                </Grid>
            </Grid>
            
            <Grid sx={{ padding: 1, mt: 3, mb: 1.5 }}>
                <Stack spacing={2} direction="row">
                    <Button
                        sx={{
                            ml: 3, p: 1.5, bgcolor: '#cc6928', '&:hover': {
                                backgroundColor: '#cc6928',
                                color: 'white',
                            },
                        }} onClick={handleClickOpen} variant="contained">
                        Ajouter une publicité
                    </Button>
                </Stack>
            </Grid>
            {/* Le tableau */}
            <Grid >
                <TableContainer sx={{ boxShadow: 15, backgroundColor: "white" }}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow sx={{ bgcolor: '#3f3f3f' }}>
                                <TableCell sx={{ p: 3, color: 'white' }}>#</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }}>Titre</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }}>Sous-titre</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }}>Photo</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }} align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            data.length > 0 
                            ?
                                data.map((donne, donnekey)=>{
                                    return (
                                        <TableRow key={donnekey}>
                                            <TableCell sx={{ p: 3 }}>
                                                {donnekey + 1}
                                            </TableCell>
                                            <TableCell sx={{ p: 3 }}>
                                                {donne.titre}
                                            </TableCell>
                                            <TableCell sx={{ p: 3 }} >{donne.stitre}</TableCell>
                                            <TableCell sx={{ p: 3 }} ><img width="200" height="200" src={`http://www.adefapp.adefgroup.com/${donne.photo}`} alt="Image" /></TableCell>
                                            <TableCell sx={{ p: 3 }} align="right">
                                                <IconButton>
                                                    <DeleteIcon onClick={() => supprimer(donne.id)} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            :
                                'No data Found'
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Dialog open={open} onSubmit={Form}>
                <form encType="multipart/form-data">
                    <DialogTitle>Enregistrer une publicité</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Remplissez chaque information et valider l'enregistrement.
                        </DialogContentText>
                        <TextField
                            required
                            margin="dense"
                            name="image"
                            label="Image"
                            type="file"
                            fullWidth
                            variant="standard"
                            accept="image/*" 
                            onChange={handleFileChange}
                        />
                        <TextField
                            required
                            margin="dense"
                            id="titre"
                            label="Titre"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setTitre(e.target.value)}
                            value={titre}
                        />
                        <TextField
                            required
                            margin="dense"
                            id="soustitre"
                            label="Sous-titre"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setStitre(e.target.value)}
                            value={stitre}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button type='submit' onClick={handleClose}>Enregistrer</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Grid>
    );
}

export default Publicites;