import React from 'react';
import Header from './Composants/Header';
import InfoContact from './Composants/InfoContact';
import Maps from './Composants/Maps';
import Form from './Composants/Form';

function Contact(props) {
    return (
        <>
            <Header />
            <InfoContact />
            <Maps />
            <Form />
        </>
    );
}

export default Contact;