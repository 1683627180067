import React, {useState} from 'react';
import chat from '../Images/chat.png'
import axios from 'axios';

function Form(props) {
    const [nom, setNom] = useState('')
    const [mail, setMail] = useState('')
    const [objet, setObjet] = useState('')
    const [message, setMessage] = useState('')

    const FormContact = (e) => {
        e.preventDefault();
        if(nom == "" || mail == "" || objet == "" || message == ""){
            alert('Un des champs est vide, veuillez le remplir...')
        }else{
            axios.post('http://www.adefapp.adefgroup.com/contact', { nom: nom, mail: mail, objet: objet, message: message })
                .then(data => {
                    console.log(data.data);
                    alert('Message envoyé, Merci de nous avoir contacté !')
                    window.location.reload()
                })
                .catch(err => {
                    alert('Vérifiez vos informations')
                })
        }
        
    }
    return (
        <div className="uk-section uk-section-muted">
            <div className="uk-container">

                

                <div>
                    <div className="uk-section-secondary uk-grid-column-small uk-grid-row-large uk-child-width-1-2@s" uk-grid="true">
                        <div>
                            
                            <div className="uk-card uk-card-body">
                                <div className='uk-position-top-center'>
                                    <img style={{width: 100, height:100}} src={chat} />
                                </div>
                                <p>
                                    <h3>ECRIVEZ-NOUS</h3>
                                    Nos équipes traiteront votre message et vous contacterons dans les plus brefs délais.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-body">
                                <form onSubmit={FormContact}>
                                    <fieldset className="uk-fieldset" style={{textAlign:'right'}}>

                                        <div className="uk-grid-small" uk-grid="true">
                                            <div className="uk-width-1-2@s">
                                                <input style={{ borderRadius: 0 }} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Votre nom" onChange={(e) => setNom(e.target.value)} value={nom} />
                                            </div>
                                            <div className="uk-width-1-2@s">
                                                <input style={{ borderRadius: 0 }} type="email" className="form-control" id="exampleFormControlInput1" placeholder="Votre mail" onChange={(e) => setMail(e.target.value)} value={mail} />
                                            </div>
                                        </div>

                                        <div className="uk-margin">
                                            <input style={{ borderRadius: 0 }} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Objet" onChange={(e) => setObjet(e.target.value)} value={objet} />
                                        </div>

                                        <div className="uk-margin">
                                            <textarea style={{ borderRadius: 0 }} className="form-control" id="exampleFormControlTextarea1" rows="8" placeholder='Votre message ici...' onChange={(e) => setMessage(e.target.value)} value={message} />
                                        </div>
                                        <button type='submit' style={{ backgroundColor: '#cc6928', color: 'white' }} className="uk-button uk-button-primary">Envoyer</button>
                                    </fieldset>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Form;