import React, { useEffect, useState } from 'react';
import axios from 'axios';
function Slider(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const Pubs = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/pubs')
                if (response) {
                    setData(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Pubs();
    }, [])
    return (
        <div>
            <div className="js-slideshow-animation uk-slideshow" uk-slideshow="min-height: 300; max-height: 750;animation: push;autoplay: true;autoplay-interval: 4000">

                <ul tabIndex={-1} className="uk-slideshow-items" style={{backgroundColor:'gray'}}>
                {
                    data.map((donne, donnekey)=>{
                        return(
                            <li key={donnekey} style={{ backgroundColor: 'gray' }} className="uk-overflow-hidden">
                                <img style={{ filter: 'brightness(75%)' }} height={300} src={`http://www.adefapp.adefgroup.com/${donne.photo}`} uk-scrollspy="cls: uk-animation-kenburns; repeat: true" alt="" />
                                <div className="uk-position-center uk-position-small uk-text-center">
                                    <h1 uk-slideshow-parallax="y: -100,0,0; opacity: 1,1,0" style={{ color: '#ed7322', fontWeight: 'bold', fontSize: "80px", fontFamily:"monospace" }} className="uk-margin-remove">{donne.titre}</h1>
                                    <p uk-slideshow-parallax="y: 100,0,0; opacity: 1,1,0" className="uk-margin-remove" style={{ fontSize: "25px", color: 'white' }}>{donne.stitre}</p>
                                </div>
                            </li>
                        )
                    })
                }
                </ul>

                <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous="true" uk-slideshow-item="previous"></a>
                <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next="true" uk-slideshow-item="next"></a>

            </div>
        </div>
    );
}

export default Slider;