import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import axios from 'axios';



function LesProduits(props) {
    const [value, setValue] = React.useState('tous');
    const [dataCateg, setDataCateg] = useState([]);
    const [dataProds, setDataProds] = useState([]);

    const handleChange = async (event) => {
        setValue(event.target.value)
        const response = await axios.post('http://www.adefapp.adefgroup.com/produits/site', { value: event.target.value })
        if (response) {
            setDataProds(response.data.results)
        } else {
            console.log('Failed to fetch image');
        }
    };
    useEffect(() => {
        const Categs = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/categories')
                if (response) {
                    console.log(response.data.results)
                    setDataCateg(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Categs();
        const Prods = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/produits')
                if (response) {
                    setDataProds(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Prods();
    }, [])
    return (
        <div>
            <div className="uk-section uk-section-default">
                <div className="uk-container">

                    <div className="container text-center">
                        <div className="row">
                            <div className="col" >
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Catégories</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="tous" control={<Radio />} label="Tous" />
                                        {
                                            dataCateg.map((donne, donnekey) => {
                                                return (
                                                    <FormControlLabel key={donnekey} value={donne.code} control={<Radio />} label={donne.description} />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="col-8">
                                <div className="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s uk-text-center" uk-grid="true">
                                    
                                    {
                                        dataProds.length > 0
                                            ?
                                            dataProds.map((donne, donnekey) => {
                                                return (
                                                    <div key={donnekey}>
                                                        <div className="uk-card uk-card-body">
                                                            <img width="200" height="200" src={`http://www.adefapp.adefgroup.com/${donne.photo}`} alt="Image" />
                                                            <div>{donne.description}</div>
                                                            <button type="button" className="btn btn-outline-dark"><a href={`/details/${donne.codeprod}`} style={{textDecoration:'none'}}>En savoir plus</a></button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            'No data Found'
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
        </div>
    );
}

export default LesProduits;