import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';


function QuiSommeNous(props) {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    const [data, setData] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const Form = (e) => {
        e.preventDefault();
        axios.post('http://www.adefapp.adefgroup.com/nous', { text: text })
            .then(data => {
                alert('Informations enregistrer')
                window.location.reload()
            })
            .catch(err => {
                alert('Vérifiez vos informations')
            })
    }
    useEffect(() => {
        const QuiSommeNous = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/nous')
                if (response) {
                    setData(response.data.results[0].quisommesnous)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log("Erreur dans le use effect de suisommesnous "+error);
            };


        }
        QuiSommeNous();
    }, [])
    return (

        <>
            <Grid sx={{ padding: 1, mt: 3, mb: 1.5 }}>
                <Stack spacing={2} direction="row">
                    <Button
                        sx={{
                            ml: 3, p: 1.5, bgcolor: '#cc6928', '&:hover': {
                                backgroundColor: '#cc6928',
                                color: 'white',
                            },
                        }} onClick={handleClickOpen} variant="contained">
                        Editer - Qui Somme Nous
                    </Button>
                </Stack>
            </Grid>
            {/* Le tableau */}
            <Grid sx={{width:'90%',ml:5}}>
                <Typography>
                    {data}
                </Typography>
            </Grid>
            <Dialog open={open} onSubmit={Form}>
                <form>
                    <DialogTitle>Editer - Qui Sommes Nous</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Remplissez chaque information et valider l'enregistrement.
                        </DialogContentText>
                        <label for="exampleFormControlTextarea1" className="form-label">Example textarea</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setText(e.target.value)} value={text}></textarea>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button onClick={handleClose} type='submit' >Enregistrer</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

export default QuiSommeNous;