import React, { useState } from "react";
import { Link, useHistory, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './Login.css'
import { ServiceUtilisateur } from "../../ServiceUtilisateur";
function Login() {
    const [id, setId] = useState('');
    const [pass, setPass] = useState('');
    const nav = useNavigate();//
    const LoginForm = (e) => {
        e.preventDefault();
        axios.post('http://www.adefapp.adefgroup.com/login',
            { id: id, pass: pass })
            .then(data => {
                ServiceUtilisateur.saveId(data.data.info[0].Id)
                ServiceUtilisateur.saveNom(data.data.info[0].Password)
                nav("/admin/dashboard");
            })
            .catch(err => {
                alert('Utilisateur non trouvé')
            })
    }
    return (
        <>
            <section className="h-100 gradient-form" style={{ backgroundColor: "#eee" }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-xl-10">
                            <div className="card rounded-3 text-black">
                                <div className="row g-0">
                                    <div className="col-lg-6">
                                        <div className="card-body p-md-5 mx-md-4">

                                            <form onSubmit={LoginForm}>
                                                <p>Connectez-vous avec vos identifiants</p>
                                                <br />
                                                <div className="form-outline">
                                                    <input
                                                        type="text"
                                                        id="form2Example11"
                                                        className="form-control"
                                                        placeholder="Entrez votre Id"
                                                        required
                                                        onChange={(e) => setId(e.target.value)}
                                                        value={id}
                                                        name="id"
                                                    />
                                                    <label className="form-label" htmlFor="form2Example11">Identifiant</label>
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <input
                                                        type="password"
                                                        id="form2Example22"
                                                        className="form-control"
                                                        placeholder="Enter password"
                                                        required
                                                        onChange={(e) => setPass(e.target.value)}
                                                        value={pass}
                                                        name="pass"
                                                    />
                                                    <label className="form-label" htmlFor="form2Example22">Password</label>
                                                </div>
                                                <input name="btnAjout" type="submit" id='submit' value='SE CONNECTER'></input>

                                            </form>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                                        <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                                            <h4 className="mb-4" style={{ fontWeight: "bold", fontSize: "35px" }}>Bienvenue sur la plateforme de ADEF</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )

}
export default Login