import React, { useEffect, useState } from 'react';
import bureau from '../Images/bureau.jpg'
import './accueil.css';
import fiable from '../Images/securite.png'
import durable from '../Images/durabilite-du-produit.png'
import performant from '../Images/performance.png'
import axios from 'axios';


function Description(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const QuiSommeNous = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/nous')
                if (response) {
                    console.log(response.data.results[0].quisommesnous)
                    setData(response.data.results[0].quisommesnous)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log("Erreur dans le use effect de suisommesnous " + error);
            };


        }
        QuiSommeNous();
    }, [])
    return (
        <>
            <div className='uk-section'>
                <div className="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid="true">
                    <div className="uk-flex-last@s uk-card-media-right uk-cover-container" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                        <img src={bureau} alt="" uk-cover="true" />
                        <canvas width="600" height="400"></canvas>
                    </div>
                    <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                        <div className="uk-card-body" style={{ textAlign: 'left' }}>
                            <h3 className="uk-card-title"><span className='qui'>Qui</span> <br /> <span className='sommenous'>sommes-nous ?</span></h3>
                            <p style={{ textAlign: 'justify' }}>
                                {data}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <nav className="navbar">
                <div className="container-fluid" style={{padding:'20px'}}>
                    <span style={{ fontSize:'25px', paddingLeft:20}}>
                        Qui sommes-nous ?
                        {/* <hr style={{color:'blue'}}/> 
                        <div style={{borderBottom:'3px solid black', width:'40%', borderRadius:'10px'}}></div>
                    </span>
                </div>
            </nav>
            <div className="row">
                <div style={{ textAlign: 'justify', paddingLeft: 70, paddingTop: 10, paddingRight: 70 }}>
                    {data}
                </div>
            </div> */}

            </div>
            {/* Cette section est pour la présentation de la qualité des produits */}
            <div className="uk-section uk-section-default desc2">
                
                <div className="uk-container">

                    <h3 style={{ textAlign: 'left' }}><span className='qui'>Nos</span> <br /> <span className='sommenous' style={{color:'white'}}>produits sont : </span></h3>

                    <div className="uk-grid-match uk-child-width-1-3@m" uk-grid="true">
                        <div  className="uk-animation-toggle" tabIndex="0">
                            <div className='uk-animation-slide-top-small'>
                                <img width={100} height={100} src={fiable}/>
                            </div>
                            <p className='proprieteproduit' style={{ color: 'white' }}>Fiable</p>
                        </div>
                        <div  className="uk-animation-toggle" tabIndex="0">
                            <div className='uk-animation-slide-top-small'>
                                <img width={100} height={100} src={durable} />
                            </div>
                            <p className='proprieteproduit'>Durable</p>
                        </div>
                        <div  className="uk-animation-toggle" tabIndex="0">
                            <div className='uk-animation-slide-top-small'>
                                <img width={100} height={100} src={performant} />
                            </div>
                            <p className='proprieteproduit'>Performant</p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Description;