import React, { useEffect, useState } from 'react';
import photo from '../Images/images.png'
import axios from 'axios';

function LesPartenaires(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const Parts = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/parts')
                if (response) {
                    console.log(response.data.results)
                    setData(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Parts();
    }, [])
    return (
        <div className="uk-section uk-section-muted">
            <div className="uk-container">

                <h3 style={{textAlign:'center'}}>Ils nous ont fait confiance</h3>

                <div>
                    <div uk-scrollspy="cls: uk-animation-slide-bottom; target: .uk-card; delay: 300; repeat: false" className="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s uk-text-center" uk-grid="true">
                        {
                            data.map((donne, donnekey) => {
                                return (
                                    <div key={donnekey}>
                                        <div style={{textAlign:'center'}} className="uk-card uk-card-body">
                                            <img width="150" height="150" src={`http://www.adefapp.adefgroup.com/${donne.photo}`} alt="Image" /><br/>
                                            <div>{donne.nom}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LesPartenaires;