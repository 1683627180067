import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';

function Produits(props) {
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dataCateg, setDataCateg] = useState([]);
    const [dataProds, setDataProds] = useState([]);
    const [categ, setCateg] = useState('');
    const [desc, setDesc] = useState('');
    const [code, setCode] = useState('');
    const [details, setDetails] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        console.log(selectedFile)
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const supprimer = (id) => {
        if (window.confirm("Vous êtes sûr de vouloir supprimer ce produit ?") == true) {
            axios.delete(`http://www.adefapp.adefgroup.com/prod/${id}`)
                .then((response) => {
                    alert("Suppression éffectuer avec succès")
                    window.location.reload();
                })
                .catch((err) => { alert("Une erreur s'est produite lors de la suppression") })
        }
    };
    const Form = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('code', code)
        formData.append('desc', desc)
        formData.append('categ', categ)
        formData.append('details', details)
        formData.append('image', selectedFile);
        console.log(formData)
        axios.post('http://www.adefapp.adefgroup.com/prod', formData)
            .then(data => {
                alert('Produit enregistrés')
                window.location.reload()
            })
            .catch(err => {
                alert('Vérifiez vos informations')
            })
    }
    useEffect(() => {
        const Categs = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/categories')
                if (response) {
                    setDataCateg(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Categs();
        const Prods = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/produits')
                if (response) {
                    setDataProds(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Prods();
    }, [])
    return (
        <Grid spacing={1} marginTop={5}>
            <Grid sx={{ display: "flex", flexDirection: "row", border: '0.5px solid #d3d3d3', borderLeft: 0, padding: 1, borderTop: 0, borderRight: 0 }}>
                <Grid md={10}>
                    <Typography variant="h6" component="div">
                        Les Produits
                    </Typography>
                </Grid>
            </Grid>
            <Grid sx={{ padding: 1, mt: 3, mb: 1.5 }}>
                <Stack spacing={2} direction="row">
                    <Button
                        sx={{
                            ml:3, p: 1.5, bgcolor: '#cc6928', '&:hover': {
                                backgroundColor: '#cc6928',
                                color: 'white',
                            },
                        }} onClick={handleClickOpen} variant="contained">
                        Ajouter un produit
                    </Button>
                </Stack>
            </Grid>
            {/* Le tableau */}
            <Grid >
                <TableContainer sx={{ boxShadow: 15, backgroundColor: "white" }}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow sx={{ bgcolor: '#3f3f3f' }}>
                                <TableCell sx={{ p: 3, color: 'white' }}>#</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }}>Code</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }}>Description</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }}>Image</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }}>Catégorie</TableCell>
                                <TableCell sx={{ p: 3, color: 'white' }} align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dataProds.length > 0
                                    ?
                                    dataProds.map((donne, donnekey) => {
                                        return (
                                            <TableRow key={donnekey}>
                                                <TableCell sx={{ p: 3 }}>
                                                    {donnekey+1}
                                                </TableCell>
                                                <TableCell sx={{ p: 3 }}>
                                                    {donne.codeprod}
                                                </TableCell>
                                                <TableCell sx={{ p: 3 }} >{donne.description}</TableCell>
                                                <TableCell sx={{ p: 3 }} ><img width="50" height="50" src={`http://www.adefapp.adefgroup.com/${donne.photo}`} alt="Image" /></TableCell>
                                                <TableCell sx={{ p: 3 }} >{donne.code}</TableCell>
                                                <TableCell sx={{ p: 3 }} align="right">
                                                    <IconButton>
                                                        <EditIcon />
                                                    </IconButton>
                                                    /
                                                    <IconButton onClick={() => supprimer(donne.codeprod)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    'No data Found'
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Dialog open={open} onSubmit={Form}>
                <form encType="multipart/form-data">
                    <DialogTitle>Enregistrer un Produit</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Remplissez chaque information et valider l'enregistrement.
                        </DialogContentText>

                        <div style={{ marginTop: '5%' }}>
                            <select onChange={(e) => setCateg(e.target.value)} value={categ} style={{ borderLeft: 0, borderRight: 0, borderRadius: 0, borderTop: 0 }} class="form-select" aria-label="Default select example">
                                <option selected>Sélectionner une catégorie</option>
                                {
                                    dataCateg.map((donne)=>{
                                        return(
                                            <option value={donne.code}>{donne.description}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <TextField
                            required
                            margin="dense"
                            name="image"
                            label="Image"
                            type="file"
                            fullWidth
                            variant="standard"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        <TextField
                            margin="dense"
                            id="code"
                            label="Code"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setCode(e.target.value)}
                            value={code}
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setDesc(e.target.value)}
                            value={desc}
                        />
                        <TextField
                            margin="dense"
                            id="details"
                            label="Détails"
                            type="textArea"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setDetails(e.target.value)}
                            value={details}
                        />
                        

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button type='submit' onClick={handleClose}>Enregistrer</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Grid>
    );
}

export default Produits;