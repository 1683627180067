import React, { useEffect, useState } from 'react';
import facebook from './Images/faceb.png'
import twitter from './Images/twitt.png'
import insta from './Images/insta.png'
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from './Images/logoadefmod2.png'
import logo2 from './Images/logotogosadefmod3.png'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import './Footer.css'

function Footer(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const Annonces = async () => {
            try {
                const response = await axios.get('http://www.adefapp.adefgroup.com/annonces')
                if (response) {
                    console.log(response.data.results)
                    setData(response.data.results)
                } else {
                    console.log('Failed to fetch image');
                }
            } catch (error) {
                console.log(error);
            };


        }
        Annonces();
    }, [])
    return (
        <div style={{ padding: "100px 70px 80px 70px", borderTop: '1px darkgrey solid' }}>
            <div className="container text-center">
                <div className="row footer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="col-md-5">
                        <div style={{ marginTop: '20px' }}>
                            <span>
                                <img width={200} src={logo} />
                                <img width={200} src={logo2} />
                            </span>
                        </div>
                        {/* <br />
                        <div>
                            <p style={{ color: 'white', textAlign: 'justify', fontSize: '15px'}}>
                                <br />
                                <br />
                                Lot 15 Parcelle 050R Cococodji Agbogboville 01 BP 5596 Cotonou<br/>
                                <LocalPhoneIcon />     00229 98 79 70 70 / 95 06 19 08<br/>
                                <EmailIcon />     infobenin@adefgroup.com
                            </p>
                        </div> */}
                    </div>
                    <div className="col-md-3" style={{ textAlign: 'left' }}>
                        <div className='titrediv'>
                            <span className='titre'>LIENS RAPIDES</span>
                        </div>
                        <div>
                            <ul className="uk-child-width-1@s uk-grid" style={{ backgroundColor: 'transparent' }}>
                                <li className='pages' style={{ backgroundColor: 'transparent',  listStyleType: 'none', fontSize: "15px" }} ><Link className='footerText' style={{ textDecoration: 'none' }} to="/accueil">Accueil</Link></li>
                                <li className='pages' style={{ backgroundColor: 'transparent',  listStyleType: 'none', fontSize: "15px" }} ><Link className='footerText' style={{ textDecoration: 'none' }} to="/produitsetservices">Nos produits et services</Link></li>
                                <li className='pages' style={{ backgroundColor: 'transparent',  listStyleType: 'none', fontSize: "15px" }} ><Link className='footerText' style={{ textDecoration: 'none' }} to="/partenaires">Nos partenaires</Link></li>
                                <li className='pages' style={{ backgroundColor: 'transparent',  listStyleType: 'none', fontSize: "15px" }} ><Link className='footerText' style={{ textDecoration: 'none' }} to="/contact">Contactez-nous</Link></li>
                                <li className='pages' style={{ backgroundColor: 'transparent',  listStyleType: 'none', fontSize: "15px" }} ><Link className='footerText' sx={{ textDecoration: 'none' }} to="/admin/dashboard">Admin</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3" style={{ textAlign: 'left' }}>
                        <div className='titrediv'>
                            <span className='titre'>SUIVEZ-NOUS</span>
                        </div>
                        <div>
                            <div className='sociaux'><li className='sociaux' style={{ backgroundColor: 'transparent', listStyleType: 'none', fontSize: "15px" }} ><Link className='footerText' style={{ textDecoration: 'none' }} to="https://x.com/AdefInfobenin?s=09"><TwitterIcon />&nbsp;Twitter</Link></li></div>
                            <div className='sociaux'><li className='sociaux' style={{ backgroundColor: 'transparent', listStyleType: 'none', fontSize: "15px" }} ><Link className='footerText' style={{ textDecoration: 'none' }} to="https://www.facebook.com/profile.php?id=61550684836054&mibextid=ZbWKwL"><FacebookIcon />&nbsp;Facebook</Link></li></div>
                            <div className='sociaux'><li className='sociaux' style={{ backgroundColor: 'transparent', listStyleType: 'none', fontSize: "15px" }} ><Link className='footerText' style={{ textDecoration: 'none' }} to="https://instagram.com/adefbenin?igshid=bGZnbzVoZTVvaW5k"><InstagramIcon />&nbsp;Instagram</Link></li></div>
                        </div>
                    </div>
                    {/* <div className="col" style={{ textAlign: 'left' }}>
                        <span className='titre'>Actualités</span>
                        <div className="uk-slider-container-offset" uk-slider="autoplay: true; autoplay-interval: 2000">

                            <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">

                                <ul className="uk-slider-items uk-child-width-1@s uk-grid">
                                    {
                                        data.length > 0 ?
                                            data.map((donne, donnekey) => {
                                                return (
                                                    <li key={donnekey}>
                                                        <div style={{ width: 'auto', color: 'gray', backgroundColor: 'transparent', boxShadow: 0 }}>
                                                            <div>
                                                                <h3 style={{ color: 'gray', fontSize: "15px" }}>{donne.titre}</h3>
                                                                <p style={{ fontSize: "14px" }}>{donne.soustitre}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )

                                            })
                                            : 'Recherche...'
                                    }

                                </ul>

                                <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous="true" uk-slider-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next="true" uk-slider-item="next"></a>

                            </div>

                            <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin" style={{ color: 'gray' }}></ul>

                        </div>
                    </div> */}

                </div>
            </div>
            <hr />
            <div className="container">
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                    <div className="col-md-12 footerText" style={{ color: 'gray' }}>
                        © 2023 ADEF. All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;