import React from 'react';
import BackImage from '../Images/partenaire.jpg'

function Header(props) {
    return (
        <div>
            <div>
                <div className="uk-height-medium uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style={{ backgroundImage: `url(${BackImage})` }}>

                    <h1 style={{ fontSize: '20px', fontWeight: 'bold' }} className="uk-width-1-2@m uk-text-center uk-margin-auto uk-margin-auto-vertical">NOS PARTENAIRES</h1>

                </div>
            </div>
        </div>
    );
}

export default Header;