import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js"; 
import Accueil from './Accueil/Accueil';
import ProduitsServices from './ProduitsServices/ProduitsServices';
import Partenaires from './Partenaires/Partenaires';
import Contact from './Contact/Contact';
import Login from './Admin/Login/Login';
import Dashboard from './Admin/Dashboard/Dashboard';
import Produits from './Admin/Produits/Produits';
import Admin from './Admin';
import Publicites from './Admin/Publicites/Publicites';
import Categories from './Admin/Categories/Categories';
import AuthGuard from './Auth/AuthGuard';
import PartenairesA from './Admin/Partenaires/Partenaires';
import ProduitsSelect from './ProduitsSelect/ProduitsSelect';
import Panier from './Panier/Panier';
import Contacts from './Admin/Contacts/Contacts';
import Apropos from './Admin/Apropos/Apropos';
import Annonces from './Admin/Annonces/Annonces';
import Commandes from './Admin/Commandes/Commandes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route exact path="accueil" element={<Accueil />} />
        <Route exact path="panier" element={<Panier />} />
        <Route exact path="produitsetservices" element={<ProduitsServices />} />
        <Route exact path="partenaires" element={<Partenaires />} />
        <Route exact path="contact" element={<Contact />} />
        <Route exact path="details/:codeprod" element={<ProduitsSelect />} />
        <Route index element={<Accueil />} />
      </Route>
      <Route exact path="/login" element={<Login />} />
      <Route path="/admin" element={<AuthGuard><Admin /></AuthGuard>}>
        <Route exact path="dashboard" element={<Dashboard />} />
        <Route exact path="produits" element={<Produits />} />
        <Route exact path="publicites" element={<Publicites />} />
        <Route exact path="categories" element={<Categories />} />
        <Route exact path="partenaires" element={<PartenairesA />} />
        <Route exact path="contacts" element={<Contacts />} />
        <Route exact path="a-propos" element={<Apropos />} />
        <Route exact path="annonces" element={<Annonces />} />
        <Route exact path="commandes" element={<Commandes />} />

      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
