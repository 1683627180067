import React, {useEffect, useState} from 'react';
import { ServiceUtilisateur } from '../ServiceUtilisateur';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

function Panier() {
    const [dataProds, setDataProds] = useState([]);
    const [nom, setNom] = useState('')
    const [mail, setMail] = useState('')
    const [nomSoc, setNomSoc] = useState('')
    const [prod, setProd] = useState([])
    const tableauObjets = ServiceUtilisateur.getProd();
    const nav = useNavigate();

    const FormPanier = (e) => {
        e.preventDefault(); 
        if(nom == "" || mail == ""){
            alert("Remplissez les champs")
        }else{
            axios.post('http://www.adefapp.adefgroup.com/commande', { tableauObjets, nom, mail, nomSoc })
                .then(data => {
                    alert('Commandes envoyée, Merci !')
                    ServiceUtilisateur.supProd()
                    window.location.reload()
                    nav("/accueil");

                })
                .catch(err => {
                    alert('Vérifiez vos informations')
                })
        }
        
 
    }

    // Vérifiez si le tableau d'objets existe et n'est pas vide
    if (!tableauObjets || tableauObjets.length === 0) {
        return <p>Aucun objet à afficher.</p>;
    }
    const deleteProd = (index) =>{
        ServiceUtilisateur.removeProd(index)
        window.location.reload()
    } 
    
    return (
        <div className="uk-section uk-section-large uk-section-muted">
            <div className="uk-container">

                

                <div className="uk-grid-match uk-child-width-1-1@m" uk-grid="true">
                    <div>
                        
                        <div className="uk-child-width-expand@s" uk-grid="true">

                            <div style={{marginLeft:'100px'}}>
                                {/* Commencement de la table du panier */}
                                <div className="uk-overflow-auto">
                                    <h1>Votre Panier</h1>
                                    <form onSubmit={FormPanier}>
                                        <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                                            <thead>
                                                <tr>
                                                    <th className="uk-table-shrink">Action</th>
                                                    <th className="uk-table-shrink">Photo</th>
                                                    <th className="uk-table-expand">Nom</th>
                                                    <th className="uk-width-small">Quantité</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {console.log(tableauObjets)}
                                                {
                                                    tableauObjets.map((donne, donnekey) => (
                                                        <tr key={donnekey}>
                                                            <td>
                                                                <button onClick={() => deleteProd(donne)}><DeleteIcon /></button>
                                                            </td>
                                                            <td className="uk-table-link">

                                                                <img width="500" height="500" src={`http://www.adefapp.adefgroup.com/${donne.tab.photo}`} alt="Image" />
                                                            </td>
                                                            <td className="uk-table-link">
                                                                <span className="uk-link-reset" href="">{donne.tab.codeprod}</span>
                                                            </td>
                                                            <td className="uk-text-truncate">
                                                                <div class="uk-margin">
                                                                    <span className="uk-link-reset" href="">{donne.qte}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        <h1>Informations supplémentaires</h1>
                                        <fieldset className="uk-fieldset" style={{ textAlign: 'right' }}>
                                            <div className="uk-grid-small" uk-grid="true">
                                                <div className="uk-width-1-2@s">
                                                    <input style={{ borderRadius: 0 }} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Votre nom" onChange={(e) => setNom(e.target.value)} value={nom} />
                                                </div>
                                                <div className="uk-width-1-2@s">
                                                    <input style={{ borderRadius: 0 }} type="email" className="form-control" id="exampleFormControlInput1" placeholder="Votre mail" onChange={(e) => setMail(e.target.value)} value={mail} />
                                                </div>
                                            </div>
                                            <div className="uk-margin">
                                                Vous êtes une société ?
                                                <input style={{ borderRadius: 0 }} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Nom de la société" onChange={(e) => setNomSoc(e.target.value)} value={nomSoc} />
                                            </div>

                                            <button type='submit' style={{ backgroundColor: 'blue', color: 'white' }} className="uk-button uk-button-primary">COMMANDER</button>
                                        </fieldset>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default Panier;