import React from 'react';

function Maps(props) {
    return (
        <div>
            <div >

                <h3 style={{ textAlign: 'center' }}>Facile de nous trouver</h3>

                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.0313097438316!2d2.264558874860867!3d6.389961524701015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x102359f32bcd89ef%3A0x486d2a4cd1a73ed6!2sADEF%20B%C3%89NIN!5e0!3m2!1sfr!2sbj!4v1692989561375!5m2!1sfr!2sbj" height="450" style={{ border: 0, width:'50%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15866.91720010555!2d1.3034491!3d6.1669934!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1023e51d401bee7b%3A0x7173f8c59742af1e!2sADEF%20TOGO!5e0!3m2!1sfr!2s!4v1693916041955!5m2!1sfr!2s" height="450" style={{ border: 0, width: '50%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

            </div>
        </div>
    );
}

export default Maps;