import React from 'react';
import Slider from './Composants/Slider';
import Description from './Composants/Description';
import VisionEtMission from './Composants/VisionEtMission';
import Nouvelle from './Composants/Nouvelle';
import Produit from './Composants/Produit';

function Accueil(props) {
    return (
       <>
            <Slider />
            <Description />
            <VisionEtMission/>
            <Produit/>
       </>
    );
}

export default Accueil;