
let saveId = (id) =>{
    localStorage.setItem('id', id)
}
let getId = () => {
    let id = localStorage.getItem('id')
    return id
}
let saveNbreShop = (id) => {
    localStorage.setItem('nbreshop', id)
}
let getNbreShop = () => {
    let id = localStorage.getItem('nbreshop') 
    if (id == null) {
        return 0
    } else {
        return id
    }
}
// let addNbreShop = (n) =>{
//     let nombre = getNbreShop()
//     nombre = nombre+1
//     saveNbreShop(nombre)
// }
let saveProd = (produit) => {
    localStorage.setItem('produits', JSON.stringify(produit))
}
let getProd = () => {
    let produit = JSON.parse(localStorage.getItem('produits'))
    if(produit == null)
    {
        return []
    }else{
        saveNbreShop(produit.length)
        return produit
    }
}
let addProd = (p) =>{
    let produits = getProd()
    produits.push(p)
    saveProd(produits)
    console.log(getProd())
    saveNbreShop(produits.length)
}
let removeProd = (product) =>{
    let prod = getProd()
    prod = prod.filter(p=>p.nom != product.nom)
    saveProd(prod)
    saveNbreShop(prod.length)
}
let saveNom = (nom) => {
    localStorage.setItem('nom', nom)
}
let getNom = () => {
    let nom = localStorage.getItem('nom')
    return nom
}
let savePrenom = (prenom) => {
    localStorage.setItem('prenom', prenom)
}
let getPrenom = () => {
    let prenom = localStorage.getItem('prenom')
    return prenom
}
let saveMail = (mail) => {
    localStorage.setItem('mail', mail)
}
let getMail = () => {
    let mail = localStorage.getItem('mail')
    return mail
}
let saveTel = (tel) => {
    localStorage.setItem('tel', tel)
}
let getTel = () => {
    let tel = localStorage.getItem('tel')
    return tel
}
let savePass = (pass) => {
    localStorage.setItem('pass', pass)
}
let getPass = () => {
    let pass = localStorage.getItem('pass')
    return pass
}

let logout = () =>{
    localStorage.removeItem('id')
    localStorage.removeItem('nom')
    localStorage.removeItem('prenom')
    localStorage.removeItem('mail')
    localStorage.removeItem('tel')
    localStorage.removeItem('pass')
}

let supProd = () => {
    localStorage.removeItem('produits')
    let prod = getProd()
    saveNbreShop(prod.length)
}

let isLogged = () =>{
    let id = localStorage.getItem('id')
    return !!id
}
/*
let getRole = () => {
    let role = localStorage.getItem('role')
    return role
}*/

export const ServiceUtilisateur = {
    getNom, 
    getPrenom, 
    saveId, 
    logout, 
    isLogged, 
    saveNom, 
    savePrenom, 
    saveMail, 
    getMail, 
    saveTel, 
    getTel, 
    savePass, 
    getPass,
    getId,
    saveProd,
    getProd,
    supProd,
    addProd,
    removeProd,
    saveNbreShop,
    getNbreShop
}